#about-div {
  display: flex;
  position: relative;
}

#about-div-photo {
  flex: 6;
}

#about-div-text {
  flex: 10;
}

#about-div-end {
  flex: 3;
}

#about {
  /* for smooth scroll */
  padding-top: 70px;
  margin-top: -70px;
}

.about-top-div {
  min-height: 50px;
}

.about-middle-div {
  min-height: 530px;
  padding: 10px;
  padding-bottom: 50px;
  margin-bottom: 5px;
}

#about-image-div {
  height: 530px;
  position: relative;
}

#about-image-top-div {
  padding: 10px;
  height: auto;
}

#about-horizontal-border-top {
  top: 50px;
}

#about-horizontal-border-second-top {
  top: 50px;
}

@media all and (max-width: 650px) {
  #about-horizontal-border-top {
    top: 400px;
  }
  #about-image-top-div {
    height: 330px;
    margin-top: 50px;
  }
  #about-div-photo,
  #about-div-end {
    flex: 1.5;
  }
  .about-middle-div {
    min-height: auto;
    margin-bottom: 20px;
  }
}

#about-horizontal-border-bottom {
  width: 70%;
  bottom: 50px;
}

.about-vertical-border {
  height: 100%;
  background-color: black;
  width: 0.5px;
  position: absolute;
}

#about-vertical-border-right {
  height: 50%;
}

#about-vertical-border-left {
  height: 100%;
}

#about-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#about-middle-div-left-side {
  width: 100%;
}

#about-page-big-text-div {
  font-size: var(--large-font-size);
  padding-bottom: 20px;
  font-weight: 500;
  border-bottom: 1px solid black;
}

#about-page-smaller-text-div {
  font-size: var(--small-font-size);
  padding-top: 20px;
}
