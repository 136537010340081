#skills-div {
  flex: 40;
  margin-bottom: var(--section-margin-bottom);
}

#skills-layout {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.skill-div {
  background: var(--main-backdrop-color);
  backdrop-filter: blur(20px) saturate(1.7);
  flex-grow: 1;
  display: flex;
  padding-right: 15px;
  width: fit-content;
  font-weight: 900;
  border-radius: 10px;
  align-items: center;
}

.skill-img-container {
  position: relative;
  width: 30px;
  margin: 15px;
  border-radius: 5px;
}

.skill-img {
  width: 100%;
  height: 100%;
}

.second-skill-img {
  margin-left: 0;
}

/* sparkles ----------------------- */
.sparkle {
  --sparkle-color: #ffffff5e;
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  animation: sparkle 2s infinite alternate;
  filter: drop-shadow(0px 0px 2px white);
  width: 30px;
  z-index: 100;
}

@keyframes sparkle {
  50% {
    opacity: 0.4;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
/* sparkles ----------------------- */
