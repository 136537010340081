#sidebar-div {
  position: fixed;
  width: var(--sidebar-width);
  left: 0;
  bottom: 20px;
  top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sidebar-img {
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar-img-container {
  width: var(--sidebar-img-width);
  height: var(--sidebar-img-width);
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
}

.initial-sidebar-img:hover {
  opacity: 0;
}

#logo-texts-container {
  display: flex;
  justify-content: flex-start;
  margin-left: -12px;
  margin-top: 12px;
}

@media all and (max-width: 500px) {
  #logo-texts-container {
    margin-left: -8.5px;
  }
}

#text1,
#text2 {
  position: absolute;

  font-size: var(--medium-font-size);
  font-weight: 400;
  text-align: center;

  user-select: none;
  /* gradients ----------------------- */
  background: linear-gradient(
    to right,
    var(--main-blue-color),
    var(--main-green-color),
    #24a2c5
  );
  background-size: 200% 200%;
  animation: rainbow 2s ease-in-out infinite;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
  transition: color 0.3s ease-in-out;
}
/* ----------------------- */
