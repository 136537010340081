.image-slider-pickers-container {
  position: absolute;
  bottom: 0;
  padding: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
  transition: opacity 0.3s;
}
