.image-slider-container {
  position: relative;
}

.image-slider-image {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

@media all and (max-width: 800px) {
  .hidden {
    opacity: 1;
  }
}

.icon-button:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: invert(25%) opacity(0.6) !important;
}

.image-slider-container:hover > .hidden {
  opacity: 1;
}
