#contact {
  padding-top: 70px;
  margin-top: -70px;
  min-height: calc(100vh - 70px);
  min-height: calc(100dvh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#contact-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
}

#contact-page-left-side-div {
  flex: 1;
  margin-top: 50px;
  font-size: var(--largest-font-size);
  font-weight: 500;
}

#contact-page-right-side-div {
  flex: 1;
  min-height: 370px;
  min-width: 270px;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#contact-page-right-side-top-div {
  font-size: var(--large-font-size);
  padding-bottom: 50px;
  padding-right: 8%;
  font-weight: 500;
  position: relative;
}

#contact-page-right-side-bottom-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-page-bolded-info {
  font-weight: 800;
  font-size: var(--medium-font-size);
}

.contact-info-child-div {
  margin-top: 50px;
}

#social-media-div,
#general-info-div {
  margin-top: 50px;
  padding-right: 8%;
}

@media all and (max-width: 800px) {
  #contact-page-right-side-div {
    margin-top: 50px;
  }
  #contact-page-left-side-div {
    margin-top: 0px;
  }
}

@media all and (max-width: 500px) {
  #contact-page-left-side-div {
    font-size: var(--largest-font-size);
    font-weight: 700;
  }

  .contact-info-child-div {
    margin-top: 25px;
  }
}
