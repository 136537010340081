html {
  scroll-behavior: smooth;
}

body,
input,
select,
button {
  margin-bottom: 0px;
  font-family: Roboto Mono, monospace;
}

#main-div {
  padding-inline: 70px;
  padding-left: var(--sidebar-width);
  margin: auto;
  max-width: 1500px;
  position: relative;
}

/* gradients ----------------------- */
body::before,
body::after {
  content: '';
  position: fixed;
  z-index: -1;
  opacity: 0.65;
}
body::before {
  background: var(--third-glow);
  border-radius: 50%;
  width: 30vw;
  height: 30vw;
  margin-left: -200px;
  filter: blur(350px);
  top: calc(50vh - 50vw / 2);
  left: calc(50vw);
  animation: animateBefore 15s cubic-bezier(0.47, 0, 0.745, 0.715) infinite;
}
body::after {
  background: var(--secondary-glow);
  border-radius: 50%;
  width: 30vw;
  height: 40vh;
  filter: blur(250px);
  top: calc(50vh - 50vw / 2);
  left: calc(50vw - 50vw / 2);
  animation: animateAfter 10s cubic-bezier(0.47, 0, 0.745, 0.715) infinite;
  z-index: -10;
}

@media all and (min-width: 2000px) {
  body::after,
  body::before {
    opacity: 0.5;
  }
}

@keyframes animateBefore {
  0% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(32vw, 45vh) scale(0.8);
  }
  60% {
    transform: translate(25vw) scale(0.9);
  }
  100% {
    transform: translateY(0, 0);
  }
}

@keyframes animateAfter {
  0% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(-30vh, 38vh) scale(1.2);
  }
  60% {
    transform: translate(-25vw) scale(1);
  }
  100% {
    transform: translateX(0);
  }
}
/* ----------------------- */

:root {
  --main-grey-color: rgb(78, 78, 78);
  --main-background-color: #eaeaea;
  --main-backdrop-color: #ffffff59;
  --largest-font-size: 90px;
  --large-font-size: 25px;
  --medium-font-size: 20px;
  --small-font-size: 16px;
  --smallest-font-size: 11px;

  --sidebar-img-width: 25px;
  --sidebar-width: 100px;

  --section-margin-bottom: 45px;

  --mobile-width-threshold: 500px;

  --text-animation-curve: cubic-bezier(0.785, 0.135, 0.15, 0.86);

  /* gradients ----------------------- */
  --main-green-color: #0bbb87;
  --main-blue-color: #4586ce;

  --secondary-glow: conic-gradient(
    from 10deg at 50% 50%,
    var(--main-green-color) 0deg,
    #75d5b9 55deg,
    #97b5da 120deg,
    var(--main-green-color) 160deg,
    transparent 360deg
  );

  --third-glow: conic-gradient(
    from 90deg at 50% 50%,
    var(--main-blue-color) 0deg,
    var(--main-green-color) 160deg,
    #7ed2da 120deg,
    #8bdce0 55deg,
    transparent 360deg
  );
  /* ----------------------- */
}

@media all and (max-width: 500px) {
  :root {
    --largest-font-size: 55px;
    --large-font-size: 20px;
    --medium-font-size: 15px;
    --small-font-size: 14px;
    --smallest-font-size: 9px;

    --sidebar-img-width: 15px;
    --sidebar-width: 40px;
  }

  #main-div {
    padding-inline: 40px;
  }
}

.gradient-test {
  position: absolute;
  top: 450px;
  width: 1500px;
  left: -300px;
  z-index: -1;
}

.main-sections {
  min-height: calc(100vh - var(--section-margin-bottom));
  min-height: calc(100dvh - var(--section-margin-bottom));
  margin-bottom: var(--section-margin-bottom);
}

.horizontal-border {
  height: 0.5px;
  position: absolute;
  left: 0;
  background-color: black;
  width: 100%;
  bottom: 0px;
}
