.fixed-button {
  text-decoration: none;
  color: black;
  font-weight: 400;
  background-color: transparent;
  border: 1px rgb(137, 137, 137) solid;
  border-radius: 20px;
  padding: 7px;
  cursor: pointer;
  background: var(--main-backdrop-color);
  backdrop-filter: blur(20px) saturate(1.7);
  /* gradients ----------------------- */
  background: linear-gradient(
    to right,
    var(--main-blue-color),
    var(--main-green-color),
    #24a2c5
  );
  background-size: 200% 200%;
  animation: rainbow 2s ease-in-out infinite;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 1);
  transition: color 0.3s ease-in-out;
}

.fixed-button:hover {
  color: rgba(0, 0, 0, 0);
}

@keyframes rainbow {
  0% {
    background-position: left;
  }
  50% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}
/* ----------------------- */

#fixed-buttons-div {
  height: 50px;
  display: flex;
  gap: 10px;
  align-items: center;
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.button-numbers {
  display: inline-block;
  font-size: var(--smallest-font-size);
  font-weight: 400;
}

@media all and (max-width: 700px) {
  #fixed-buttons-div {
    display: none;
  }
}
