#experience-skills-div {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 20px;
  flex-wrap: wrap;
}

@media all and (max-width: 1200px) {
  #experience-skills-div {
    grid-template-columns: auto;
  }
}

#experience {
  flex: 60;
  padding-top: 20px;
  margin-top: -20px;
}

#experience-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  flex-wrap: wrap;
}

@media all and (max-width: 900px) {
  #experience-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media all and (max-width: 700px) {
  #experience-grid {
    grid-template-columns: 1fr;
  }
}

.experience-div {
  margin-top: 0;
  flex: 1 0 0;
  height: fit-content;
  background: var(--main-backdrop-color);
  backdrop-filter: blur(20px) saturate(1.7);
  cursor: pointer;
  transition: scale 0.3s;
  border-radius: 10px;
  overflow: hidden;
}

.experience-div:hover {
  scale: 1.05;
}

.experience-photo-div {
  height: 190px;
  width: 100%;
}

.experience-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: optimizeSpeed;
}

.name-div {
  margin-top: 12px;
  font-weight: bold;
  font-size: var(--large-font-size);
}

.date-div,
.place-div {
  font-size: var(--small-font-size);
}

.date-div {
  margin-top: 12px;
  color: var(--main-grey-color);
  font-weight: 400;
}

.place-div {
  font-weight: 500;
}

.description-div {
  margin-top: 13px;
  font-weight: 500;
  font-size: var(--medium-font-size);
  margin-bottom: 13px;
}

.modal-visible {
  opacity: 1 !important;
  display: flex !important;
}
