#home-div {
  position: relative;
  width: 100%;
}

#background-model {
  position: absolute;
  right: 0px;
  top: -100px;
  z-index: -1;
  height: 400px;
  width: 35%;
  animation: 2.15s ease-in-out 0s 1 floatInFromTheBottom;
}

@keyframes floatInFromTheBottom {
  0% {
    transform: translateY(150px);
  }
  55% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}

:root {
  --margin-top: 100px;
}

#home {
  padding-top: var(--margin-top);
  display: flex;
  justify-content: center;
  align-items: center;
}

#current-position {
  font-size: var(--large-font-size);
  color: var(--main-grey-color);
  display: flex;
  align-items: center;
  font-weight: 500;
}

#name {
  font-size: var(--largest-font-size);
  color: black;
  z-index: 2;
  font-weight: 500;
}

#home-page-line {
  margin-top: 45px;
  margin-bottom: 45px;
}

#home-page-all-bottom-items-div {
  margin-top: 250px;
  margin-bottom: 45px;
}

#home-page-below-bottom-line-items-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#home-page-additional-text-div {
  font-size: var(--small-font-size);
  width: 35%;
}

#home-page-expand-button-div {
  width: 35%;
}

@media all and (max-width: 800px) {
  #home-page-additional-text-div {
    width: 45%;
  }
  #home-page-expand-button-div {
    width: 45%;
  }
  #home-page-all-bottom-items-div {
    margin-top: 150px;
  }
}

@media all and (max-width: 500px) {
  #home-page-all-bottom-items-div {
    margin-top: 100px;
  }
  #background-model {
    top: -140px;
  }
}

/* contact me button -----------------------*/
.expand-button {
  font-kerning: none;
  background: var(--main-backdrop-color);
  backdrop-filter: blur(20px) saturate(1.7);

  width: 100%;
  height: 50px;
  border-radius: 50% / 50% 50% 50% 50%;
  /* border-radius: 20px; */
  border: 1px solid black;
  cursor: pointer;
  font-weight: 500;
  font-size: var(--small-font-size);
}

.char {
  transform: translateY(-1px);
  display: inline-block;
  white-space: pre-wrap;
  background: linear-gradient(
    to right,
    var(--main-blue-color),
    var(--main-green-color),
    #24a2c5
  );
  background-size: calc(100% * var(--chars)) 200%;
  background-position: calc(100% * (var(--index)));
  background-clip: text;
  -webkit-background-clip: text;
  transition: all calc(0.055s * (var(--index) + 1)) var(--text-animation-curve);
}

.expand-button:hover > .text-inhanced > .char {
  transform: translateY(-3px);
  color: rgba(0, 0, 0, 0);
}
/* -----------------------*/

#greetings-text-div {
  background-color: var(--main-blue-color);
  width: max-content;
  background-image: linear-gradient(
    45deg,
    var(--main-blue-color) 22%,
    var(--main-green-color) 67%,
    #24a2c5 40%,
    rgba(64, 223, 163, 0)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: intro-gradient 1.2s cubic-bezier(0.85, 0.26, 0.89, 0.93);
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
  animation-delay: 0.3s;
  background-size: 150% 100%;
  background-repeat: no-repeat;
}
@keyframes intro-gradient {
  0% {
    background-position: 300% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
}

#home-page-above-bottom-line-items-div {
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  max-height: 20px;
}

.home-page-icon-img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

#above-line-text {
  padding-bottom: 3px;
}
