.experience-modal-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 10 / 11;
  width: 65%;
  background-color: var(--main-background-color);
  padding: 25px;
  border-radius: 3px;
  flex-direction: column;
  max-height: 1550px;
  overflow-y: auto;
  height: 80%;
  container-type: inline-size;
  display: flex;
  transition: opacity 0.3s, visibility 0.3s; /* idk if looks good*/
}

.experience-modal-div:focus {
  outline: none;
}

@media all and (min-width: 750px) {
  .experience-modal-div {
    width: auto;
    max-width: 90%;
  }
  .modal-right-side {
    width: 100%;
  }
}

.modal-right-side {
  width: 65%;
}

.modal-left-side {
  width: 35%;
}

.experience-modal-top-part-div {
  display: flex;
  gap: 15px;
  height: 27vh;
  height: 27dvh;
}

.experience-modal-bottom-part-div {
  padding-top: 30px;
  display: flex;
  gap: 15px;
  position: relative;
  flex-grow: 2;
  height: 40vh;
  height: 40dvh;
}

@container (max-width: 350px) {
  .experience-modal-top-part-div {
    height: 33vh;
    height: 33dvh;
  }
  .experience-modal-bottom-part-div {
    height: 34vh;
    height: 34dvh;
  }
}

@media all and (max-width: 630px) {
  .experience-modal-bottom-part-div {
    max-height: none;
    flex-direction: column;
  }
  .modal-right-side {
    width: 100%;
  }
}

.experience-modal-top-part-left-div {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.experience-modal-category-header-div {
  font-size: var(--smallest-font-size);
  font-weight: 1000;
  flex-grow: 2;
}

.experience-modal-name-div {
  font-size: var(--medium-font-size);
  font-weight: 1000;
  flex-grow: 10;
}

.experience-modal-date-and-place-div {
  font-size: var(--smallest-font-size);
}

.experience-modal-bottom-part-left-div {
  font-size: var(--small-font-size);
  font-weight: 1000;
}

.experience-modal-bottom-part-right-div {
  font-size: var(--small-font-size);
  overflow-y: auto;
  margin-bottom: 20px;
}

.experience-modal-bottom-buttons-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-grow: 1;
}
